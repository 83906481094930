<template>
  <v-app id="dashboard-view">
    <!-- <core-filter /> -->

    <core-inner-toolbar v-if="showHeader" />

    <core-drawer ref="drawer" :key="permissionChanged" v-if="showHeader" />

    <v-main class="page-main-bg">
      <v-fade-transition mode="out-in">
        <router-view v-on="$listeners">
          <!-- <CustomerFilter v-if="isCustomer && customerDefaultRole"/> -->
        </router-view>
      </v-fade-transition>
    </v-main>

    <!-- <core-footer v-if="$route.meta.name !== 'Maps'" /> -->
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'DashboardView',
  components: {
    CustomerFilter: () => import('@/components/core/CustomerFilter.vue')
  },
  metaInfo() {
    return {
      title: 'هكتار بلس'
    }
  },
  data: () => {
    return {
      permissionChanged: 0
    }
  },
  mounted() {
    this.$refs.drawer.reRenderSideNav()
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    ...mapState('accountState', ['customerDefaultRole']),
    showHeader() {
      return !(
        this.$route.name === 'help-center' ||
        this.$route.name === 'terms&conditions'
      )
    }
  }
}
</script>
